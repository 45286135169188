import { t } from "i18next";
import React, { useState, forwardRef } from "react";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";

const CustomToggle = forwardRef(
  ({ children, onClick, isHovered, setIsHovered }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        color: isHovered ? "#fff" : "#E47100",
        backgroundColor: isHovered ? "#E47100" : "transparent",
        border: "2px solid #E47100",
        borderRadius: "4px",
        padding: "0.5rem 1rem",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "background-color 0.3s, color 0.3s",
        minWidth: "120px",
        userSelect: "none",
        marginTop: "13px",
      }}
      aria-haspopup="true"
      aria-expanded="false"
      role="button"
      tabIndex="0"
      onKeyPress={(e) => {
        if (e.key === "Enter") onClick(e);
      }}
    >
      <span>{children}</span>
      <FaChevronDown
        style={{
          transition: "transform 0.3s",
          transform: isHovered ? "rotate(180deg)" : "rotate(0deg)",
        }}
      />
    </div>
  )
);

const StatusDropdown = ({ selectedStatus, handleStatusSelect }) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const options = [
    { label: t("free"), value: "free" },
    { label: t("used"), value: "used" },
  ];

  const dropdownMenuStyle = {
    backgroundColor: "#fff",
    border: "1px solid #E47100",
    borderRadius: "4px",
    marginTop: "0.5rem",
    minWidth: "150px",
    zIndex: 1000,
  };

  const dropdownItemStyle = (value) => ({
    color: hoveredItem === value ? "#fff" : "#E47100",
    backgroundColor: hoveredItem === value ? "#E47100" : "#fff",
    padding: "0.5rem 1rem",
    cursor: "pointer",
    transition: "background-color 0.3s, color 0.3s",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  });

  

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        isHovered={isButtonHovered}
        setIsHovered={setIsButtonHovered}
      >
        {options.find((option) => option.value === selectedStatus)?.label ||
          "Select Status"}
      </Dropdown.Toggle>

      <Dropdown.Menu style={dropdownMenuStyle} aria-label="Status Options">
        {options.map((option) => (
          <Dropdown.Item
            as="div"
            key={option.value}
            onClick={() => handleStatusSelect(option.value)}
            style={dropdownItemStyle(option.value)}
            onMouseEnter={() => setHoveredItem(option.value)}
            onMouseLeave={() => setHoveredItem(null)}
            role="option"
            aria-selected={selectedStatus === option.value}
            tabIndex="0"
            onKeyPress={(e) => {
              if (e.key === "Enter") handleStatusSelect(option.value);
            }}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatusDropdown;
