import "../Styles/MyOrders.css";
import { AiOutlineSearch } from "react-icons/ai";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import { myOrders, filters } from "../api";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { BsCash } from "react-icons/bs";
import Invoice from "../Components/Invoice";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import { GrPowerReset } from "react-icons/gr";

function OrdersList(props) {
  const { t, i18n } = useTranslation();
  let history = useNavigate();
  const [loading, setLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allOrders, setAllOrders] = useState([]);
  const [filterData, setFilterData] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [invoiceModal, setInvoiceModal] = useState(false);

  var today = new Date();
  var today2 = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + "-" + mm + "-" + yyyy.toString().slice(2, 5);

  today2 = yyyy + "-" + mm + "-" + dd;
  const [resp, setResp] = useState();

  let phoneRef = useRef();
  let timeFromRef = useRef();
  let timeToRef = useRef();
  let statusRef = useRef();
  let branchRef = useRef();
  let typeRef = useRef();
  let paymentRef = useRef();
  let platformRef = useRef();
  let orderIdRef = useRef();
  let transactionIdRef = useRef();
  let codeRef = useRef();
  let agentRef = useRef();
  let sourceRef = useRef();
  let syncRef = useRef();
  let externalOrderIdRef = useRef();

  //myOrders api takes token and gets all orders with param: default filters
  //filters api takes token and gets all
  useEffect(() => {
    const token = localStorage.getItem("token");
    let params = {
      agentId: localStorage.getItem("userId"),
      dateFrom: today2,
      page: 1,
    };

    myOrders(token, params)
      .then((response) => {
        filters(token).then((response) => {
          setFilterData(response.data.data);
          setLoading(false);
        });
        setResp(response.data.data);

        let ordersArr = [];
        let sortedArr = [];
        for (let i = 0; i < response.data.data.data.length; i++) {
          let obj = {};
          obj.id = (
            <div className="detailShow">
              <Link
                to={{ pathname: `/my-order/${response.data.data.data[i].id}` }}
              >
                {response.data.data.data[i].id}
              </Link>
            </div>
          );
          obj.idSrch = response.data.data.data[i].id;
          obj.orderCode = result[i].orderCode;
          obj.userName = response.data.data.data[i].userName;
          obj.userPhone = response.data.data.data[i].userPhone;
          obj.createdAt = response.data.data.data[i].createdAt;
          obj.creatorName = response.data.data.data[i].creatorName;
          obj.typeName =
            i18n.language == "ar"
              ? response.data.data.data[i].typeArName
              : response.data.data.data[i].typeEnName;
          obj.totalPrice = response.data.data.data[i].totalPrice;
          obj.externalOrderId = response.data.data.data[i].externalOrderId;
          obj.source = response.data.data.data[i].source;
          obj.date = response.data.data.data[i].date;
          obj.time = response.data.data.data[i].time;
          obj.branchName =
            i18n.language == "ar"
              ? response.data.data.data[i].branchArName
              : response.data.data.data[i].branchEnName;
          obj.paymentMethod =
            response.data.data.data[i].paymentMethod === "tap" ? (
              response.data.data.data[i].paymentMethod === "tap" &&
              response.data.data.data[i].statusId !== 6 ? (
                <p>
                  {t("singleOrder.paid")}{" "}
                  {response.data.data.data[i].transactionId !== null ? (
                    <p>{response.data.data.data[i].transactionId}</p>
                  ) : (
                    ""
                  )}
                </p>
              ) : (
                <p>
                  {t("singleOrder.unpaid")}{" "}
                  {response.data.data.data[i].transactionId !== null ? (
                    <p>{response.data.data.data[i].transactionId}</p>
                  ) : (
                    ""
                  )}
                </p>
              )
            ) : (
              <p>
                {response.data.data.data[i].paymentMethod}{" "}
                {response.data.data.data[i].transactionId !== null ? (
                  <p>{response.data.data.data[i].transactionId}</p>
                ) : (
                  ""
                )}
              </p>
            );
          obj.status =
            response.data.data.data[i].statusId == 1
              ? t("singleOrder.order_accepted")
              : response.data.data.data[i].statusId == 2
              ? response.data.data.data[i].type.id == 2
                ? t("singleOrder.order_InProgress")
                : t("singleOrder.order_InProgressBranch")
              : response.data.data.data[i].statusId == 3
              ? t("singleOrder.order_with_driver")
              : response.data.data.data[i].statusId == 6
              ? t("singleOrder.waiting")
              : response.data.data.data[i].type.id == 2
              ? t("singleOrder.done_order")
              : t("singleOrder.done_orderBranch");
          obj.code = response.data.data.data[i].code;
          obj.offer = response.data.data.data[i].offer;
          obj.method = response.data.data.data[i].method;
          obj.platform = response.data.data.data[i].platform;
          obj.sync =
            response.data.data.data[i].sync == 1
              ? t("orders.synced")
              : t("orders.not-synced");
          obj.notes = response.data.data.data[i].notes;
          obj.canceledBy = response.data.data.data[i].cancelatorName;
          obj.rate = response.data.data.data[i].rate;
          obj.invoice = response.data.data.data[i].invoice;

          //  obj.invoice =   <button onClick={()=>{setInvoiceData(response.data.data.data[i].invoice); setInvoiceModal(true)}}><BsCash size={25}/></button>

          ordersArr.push(obj);
        }

        setAllOrders(ordersArr);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        } else if (error.response.status === 403) {
          props.setErr(true);
          props.setMsg(t("validation.noPermission"));
          props.setStyle({ top: "50%" });

          setTimeout(function () {
            props.setErr(false);
            history("/dashboard");
          }, 2500);
        }
      });
  }, []);

  function filter(page) {
    setTableLoading(true);
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");
    setPage(page);
    let params = {
      orderId: orderIdRef.current.value,
      transactionId: transactionIdRef.current.value,
      externalOrderId: externalOrderIdRef.current.value,
      statusId: statusRef.current.value,
      codeId: codeRef.current.value,
      agentId: agentRef.current.value,
      branchId: branchRef.current.value,
      sourceCode: sourceRef.current.value,
      typeId: typeRef.current.value,
      paymentMethod: paymentRef.current.value,
      sync: syncRef.current.value,
      platform: platformRef.current.value,

      dateFrom: timeFromRef.current.value,
      dateTo: timeToRef.current.value,
      userPhone: phoneRef.current.value,
      page: page,
    };
    myOrders(token, params)
      .then((response) => {
        setResp(response.data.data);

        let ordersArr = [];
        let sortedArr = [];
        if (response.data.data.toString() == []) {
          setAllOrders([]);
        } else {
          const result = Object.values(response.data.data.data);

          console.log(result[0]);

          for (let i = 0; i < result.length; i++) {
            let obj = {};
            obj.id = (
              <div className="detailShow">
                <Link to={{ pathname: `/my-order/${result[i].id}` }}>
                  {result[i].id}
                </Link>
              </div>
            );
            obj.idSrch = result[i].id;
            obj.orderCode = result[i].orderCode;
            obj.userName = result[i].userName;
            obj.userPhone = result[i].userPhone;
            obj.createdAt = result[i].createdAt;
            obj.creatorName = result[i].creatorName;
            obj.typeName =
              i18n.language == "ar"
                ? result[i].typeArName
                : result[i].typeEnName;
            obj.totalPrice = result[i].totalPrice;
            obj.externalOrderId = result[i].externalOrderId;
            obj.source = result[i].source;
            obj.date = result[i].date;
            obj.time = result[i].time;
            obj.branchName =
              i18n.language == "ar"
                ? result[i].branchArName
                : result[i].branchEnName;
            obj.paymentMethod =
              result[i].paymentMethod === "tap" ? (
                result[i].paymentMethod === "tap" &&
                result[i].statusId !== 6 ? (
                  <p>
                    {t("singleOrder.paid")}{" "}
                    {result[i].transactionId !== null ? (
                      <p>{result[i].transactionId}</p>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  <p>
                    {t("singleOrder.unpaid")}{" "}
                    {result[i].transactionId !== null ? (
                      <p>{result[i].transactionId}</p>
                    ) : (
                      ""
                    )}
                  </p>
                )
              ) : (
                <p>
                  {result[i].paymentMethod}{" "}
                  {result[i].transactionId !== null ? (
                    <p>{result[i].transactionId}</p>
                  ) : (
                    ""
                  )}
                </p>
              );
            obj.status =
              result[i].statusId == 1
                ? t("singleOrder.order_accepted")
                : result[i].statusId == 2
                ? result[i].type.id == 2
                  ? t("singleOrder.order_InProgress")
                  : t("singleOrder.order_InProgressBranch")
                : result[i].statusId == 3
                ? t("singleOrder.order_with_driver")
                : result[i].statusId == 6
                ? t("singleOrder.waiting")
                : result[i].type.id == 2
                ? t("singleOrder.done_order")
                : t("singleOrder.done_orderBranch");
            obj.code = result[i].code;
            obj.offer = result[i].offer;
            obj.method = result[i].method;
            obj.platform = result[i].platform;
            obj.sync =
              result[i].sync == 1 ? t("orders.synced") : t("orders.not-synced");
            obj.notes = result[i].notes;
            obj.canceledBy = result[i].cancelatorName;
            obj.rate = result[i].rate;
            obj.invoice = result[i].invoice;

            //   obj.invoice =   <button onClick={()=>{setInvoiceData(result[i].invoice); setInvoiceModal(true)}}><BsCash size={25}/></button>

            ordersArr.push(obj);
          }

          setAllOrders(ordersArr);
        }

        setTableLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  const data = {
    columns: [
      {
        label: t("orders.order_id"),
        field: "id",
        sort: "asc",
      },
      {
        label: t("orders.name"),
        field: "userName",
        sort: "asc",
      },
      {
        label: t("orders.phone"),
        field: "userPhone",
        sort: "asc",
      },

      {
        label: t("orders.creation"),
        field: "createdAt",
        sort: "asc",
      },
      {
        label: t("orders.creator"),
        field: "creatorName",
        sort: "asc",
      },

      {
        label: t("orders.orderDate"),
        field: "date",
        sort: "asc",
      },
      {
        label: t("orders.time"),
        field: "time",
        sort: "asc",
      },
      {
        label: t("orders.orderType"),
        field: "typeName",
        sort: "asc",
      },
      {
        label: t("orders.orderTotal"),
        field: "totalPrice",
        sort: "asc",
      },
      {
        label: t("orders.branch"),
        field: "branchName",
        sort: "asc",
      },
      {
        label: t("orders.paymentMethod"),
        field: "paymentMethod",
        sort: "asc",
      },
      {
        label: t("orders.platform"),
        field: "platform",
        sort: "asc",
      },
      {
        label: t("orders.source"),
        field: "source",
        sort: "asc",
      },

      {
        label: t("addOrder.status"),
        field: "status",
        sort: "asc",
      },
      {
        label: t("orders.code"),
        field: "code",
        sort: "asc",
      },
      {
        label: t("orders.offer"),
        field: "offer",
        sort: "asc",
      },
      {
        label: t("orders.method"),
        field: "method",
        sort: "asc",
      },

      {
        label: t("orders.externalOrder"),
        field: "externalOrderId",
        sort: "asc",
      },
      {
        label: t("orders.sync"),
        field: "sync",
        sort: "asc",
      },
      {
        label: t("menu.notes"),
        field: "notes",
        sort: "asc",
      },
      {
        label: t("orders.canceled"),
        field: "canceledBy",
        sort: "asc",
      },
      {
        label: t("orders.rate"),
        field: "rate",
        sort: "asc",
      },

      {
        label: t("orders.invoice"),
        field: "invoice",
        sort: "asc",
      },
    ],
    rows: allOrders,
  };

  function resetData() {
    var selectElement = document.getElementsByClassName("listSelect");

    for (var i = 0; i < selectElement.length; i++) {
      selectElement[i].selectedIndex = null;
    }

    timeFromRef.current.value = null;
    timeToRef.current.value = null;
    phoneRef.current.value = null;
    externalOrderIdRef.current.value = null;
    orderIdRef.current.value = null;
    transactionIdRef.current.value = null;

    setTableLoading(true);
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");
    setPage(1);
    let params = {
      page: 1,
    };
    myOrders(token, params)
      .then((response) => {
        setResp(response.data.data);

        let ordersArr = [];
        let sortedArr = [];
        if (response.data.data.toString() == []) {
          setAllOrders([]);
        } else {
          const result = Object.values(response.data.data.data);

          console.log(result[0]);

          for (let i = 0; i < result.length; i++) {
            let obj = {};
            obj.id = (
              <div className="detailShow">
                <Link to={{ pathname: `/my-order/${result[i].id}` }}>
                  {result[i].id}
                </Link>
              </div>
            );
            obj.idSrch = result[i].id;
            obj.orderCode = result[i].orderCode;
            obj.userName = result[i].userName;
            obj.userPhone = result[i].userPhone;
            obj.createdAt = result[i].createdAt;
            obj.creatorName = result[i].creatorName;
            obj.typeName =
              i18n.language == "ar"
                ? result[i].typeArName
                : result[i].typeEnName;
            obj.totalPrice = result[i].totalPrice;
            obj.externalOrderId = result[i].externalOrderId;
            obj.source = result[i].source;
            obj.date = result[i].date;
            obj.time = result[i].time;
            obj.branchName =
              i18n.language == "ar"
                ? result[i].branchArName
                : result[i].branchEnName;
            obj.paymentMethod =
              result[i].paymentMethod === "tap" ? (
                result[i].paymentMethod === "tap" &&
                result[i].statusId !== 6 ? (
                  <p>
                    {t("singleOrder.paid")}{" "}
                    {result[i].transactionId !== null ? (
                      <p>{result[i].transactionId}</p>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  <p>
                    {t("singleOrder.unpaid")}{" "}
                    {result[i].transactionId !== null ? (
                      <p>{result[i].transactionId}</p>
                    ) : (
                      ""
                    )}
                  </p>
                )
              ) : (
                <p>
                  {result[i].paymentMethod}{" "}
                  {result[i].transactionId !== null ? (
                    <p>{result[i].transactionId}</p>
                  ) : (
                    ""
                  )}
                </p>
              );
            obj.status =
              result[i].statusId == 9
                ? t("waitingSession")
                : result[i].statusId == 1
                ? t("singleOrder.order_accepted")
                : result[i].statusId == 2
                ? result[i].type.id == 2
                  ? t("singleOrder.order_InProgress")
                  : t("singleOrder.order_InProgressBranch")
                : result[i].statusId == 3
                ? t("singleOrder.order_with_driver")
                : result[i].statusId == 6
                ? t("singleOrder.waiting")
                : result[i].type.id == 2
                ? t("singleOrder.done_order")
                : t("singleOrder.done_orderBranch");
            obj.code = result[i].code;
            obj.offer = result[i].offer;
            obj.method = result[i].method;
            obj.platform = result[i].platform;
            obj.sync =
              result[i].sync == 1 ? t("orders.synced") : t("orders.not-synced");
            obj.notes = result[i].notes;
            obj.canceledBy = result[i].cancelatorName;
            obj.rate = result[i].rate;
            obj.invoice = result[i].invoice;

            //   obj.invoice =   <button onClick={()=>{setInvoiceData(result[i].invoice); setInvoiceModal(true)}}><BsCash size={25}/></button>

            ordersArr.push(obj);
          }

          setAllOrders(ordersArr);
        }

        setTableLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }
      });
  }

  return (
    <div>
      <div
        className="container-fluid"
        style={{ padding: "0 2em", paddingBottom: "5em" }}
      >
        {invoiceModal ? (
          <Invoice data={invoiceData} setModal={setInvoiceModal} />
        ) : null}

        {loading ? (
          <div style={{ height: "60vh" }} className="loader">
            <BeatLoader color={"#E47100"} loading={loading} size={30} />
          </div>
        ) : (
          <>
            <div
              className={
                i18n.language == "ar" ? "backBtn" : "backBtn backBtnEn"
              }
            >
              <button onClick={() => history(-1)}>
                {i18n.language == "ar" ? (
                  <BsArrowRightShort size={30} />
                ) : (
                  <BsArrowLeftShort size={30} />
                )}
                {t("orders.back")}
              </button>
            </div>

            <div className="container-fluid filtering">
              <div className="row ">
                <div className="col-12">
                  <div className="container-fluid">
                    <div
                      className="row gy-3"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <div className="col-12 filterTitile">
                        <p>{t("orders.filter")}</p>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("singleOrder.phone")}</label>
                        <input ref={phoneRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.from")}</label>
                        <input
                          type="date"
                          ref={timeFromRef}
                          id="dateFrom"
                          defaultValue={today2}
                        />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.to")}</label>
                        <input type="date" ref={timeToRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.externalOrder")}</label>

                        <input type="number" ref={externalOrderIdRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.order_id")}</label>

                        <input type="number" ref={orderIdRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <label>{t("orders.transactionId")}</label>

                        <input type="number" ref={transactionIdRef} />
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        {/*
<MultiSelect display="chip" optionLabel={i18n.language=="ar"?"arName":"enName"} optionValue="arName" value={cities} options={branches} onChange={(e) => setCities(e.value)} />
          */}

                        <select
                          defaultValue=""
                          ref={statusRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("orders.statuses")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.statuses &&
                            filterData.statuses.map((data, index) => (
                              <option key={index} value={data.id}>
                                {i18n.language == "ar"
                                  ? data.arName
                                  : data.enName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={branchRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("orders.branches")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.branches &&
                            filterData.branches.map((data, index) => (
                              <option key={index} value={data.id}>
                                {i18n.language == "ar"
                                  ? data.arName
                                  : data.enName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={typeRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("addOrder.type")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.types &&
                            filterData.types.map((data, index) => (
                              <option key={index} value={data.id}>
                                {i18n.language == "ar"
                                  ? data.arName
                                  : data.enName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={paymentRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("orders.paymentMethod")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.payments &&
                            filterData.payments.map((data, index) => (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={syncRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("orders.sync")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          <option value="1">{t("orders.synced")}</option>
                          <option value="0">{t("orders.not-synced")}</option>
                        </select>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={codeRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("orders.codes")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.codes &&
                            filterData.codes.map((data, index) => (
                              <option key={index} value={data.id}>
                                {data.code}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue={localStorage.getItem("userId")}
                          ref={agentRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("orders.agent")}
                          </option>
                          <option value="">{t("areas.all")}</option>
                          {filterData.agents &&
                            filterData.agents.map((data, index) => (
                              <option key={index} value={data.id}>
                                {data.userName}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={platformRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("orders.platforms")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.platforms &&
                            filterData.platforms.map((data, index) => (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="col-md-2 col-sm-4 col-6">
                        <select
                          defaultValue=""
                          ref={sourceRef}
                          className="listSelect"
                        >
                          <option value="" disabled>
                            {t("orders.sources")}
                          </option>
                          <option value="">{t("areas.all")}</option>

                          {filterData.sources &&
                            filterData.sources.map((data, index) => (
                              <option key={index} value={data.code}>
                                {data.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div
                        className="col-md-2 col-sm-4 col-6"
                        style={{ textAlign: "end" }}
                      >
                        <button
                          onClick={() => filter(1)}
                          disabled={tableLoading}
                          className="filter"
                        >
                          {t("orders.filter")}
                        </button>
                      </div>
                      <div
                        className="col-md-3 col-sm-5 col-6"
                        style={{ textAlign: "start" }}
                      >
                        <button onClick={resetData} className="reset">
                          {t("offer.reset")} <GrPowerReset color="#ffff" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="title">{t("orders.orders")}</p>
            <div className="dateFilter">
              <div className={i18n.language == "en" ? "filterEn" : null}>
                {/*
<button onClick={filterDate} >{t("orders.filter")}</button>
<input className='datePicker' type="date" ref={dateRef} onChange={handleChange} defaultValue={today2.toString()}/>
          */}
              </div>
            </div>
            {tableLoading ? (
              <div style={{ height: "30vh" }} className="loader">
                <BeatLoader
                  color={"#E47100"}
                  loading={tableLoading}
                  size={30}
                />
              </div>
            ) : (
              <>
                {/*
<div  className={i18n.language=="ar"?'container-fluid pagAr orderDet':"container-fluid orderDet"}>

          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <MDBDataTable
                                infoLabel={[t("orders.info1"), t("orders.info2"), t("orders.info3"), t("orders.info4")]} 
                                searchLabel={t("dashboard.search")}
                                paginationLabel={ [t("orders.prev"), t("orders.next")]}
                                entriesLabel={t("orders.show")}
                                    striped
                                    hover
                                    noBottomColumns={true}
                                    data={data}
                                />  
                            </div>
                        </div>
</div>*/}

                <div
                  style={{ overflow: "auto" }}
                  className={allOrders.length == 0 ? "emptyData" : null}
                >
                  <table className="allOrders">
                    <tr>
                      <th>{t("orders.order_id")}</th>
                      <th>{t("orders.orderCode")}</th>

                      <th>{t("orders.name")}</th>
                      <th>{t("orders.phone")}</th>
                      <th>{t("orders.creation")}</th>
                      <th>{t("orders.creator")}</th>
                      <th>{t("orders.orderDate")}</th>
                      <th>{t("orders.time")}</th>
                      <th>{t("orders.orderType")}</th>
                      <th>{t("orders.orderTotal")}</th>
                      <th>{t("orders.branch")}</th>
                      <th>{t("orders.paymentMethod")}</th>

                      <th> {t("orders.code")}</th>
                      <th> {t("orders.offer")}</th>
                      <th> {t("orders.method")}</th>

                      <th> {t("orders.platform")}</th>
                      <th>{t("orders.source")}</th>
                      <th> {t("addOrder.status")}</th>

                      <th> {t("orders.externalOrder")}</th>
                      <th> {t("orders.sync")}</th>
                      <th> {t("menu.notes")}</th>
                      <th> {t("orders.canceled")}</th>
                      <th> {t("orders.rate")}</th>
                      <th> {t("orders.invoice")}</th>
                    </tr>
                    {allOrders.length == 0 ? (
                      <tr>
                        <td colSpan={23}>{t("addOrder.noResult")}</td>
                      </tr>
                    ) : null}
                    {allOrders &&
                      allOrders.map((data, index) => (
                        <tr key={index}>
                          <td>{data.id}</td>
                          <td>{data.orderCode}</td>
                          <td>{data.userName}</td>
                          <td>{data.userPhone}</td>
                          <td>{data.createdAt}</td>
                          <td>{data.creatorName}</td>
                          <td>{data.date}</td>
                          <td>{data.time}</td>
                          <td>{data.typeName}</td>
                          <td>{data.totalPrice}</td>
                          <td>{data.branchName}</td>
                          <td>{data.paymentMethod}</td>

                          <td>{data.code}</td>
                          <td>{data.offer}</td>
                          <td>{data.method}</td>
                          <td>{data.platform}</td>
                          <td>{data.source}</td>
                          <td>{data.status}</td>

                          <td>{data.externalOrderId}</td>
                          <td>{data.sync}</td>
                          <td>{data.notes}</td>
                          <td>{data.canceledBy}</td>
                          <td>{data.rate}</td>
                          <td>
                            <button
                              onClick={() => {
                                setInvoiceData(data.invoice);
                                setInvoiceModal(true);
                              }}
                            >
                              <BsCash size={25} />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>

                {allOrders.length == 0 ? null : (
                  <>
                    <Stack spacing={0}>
                      <Pagination
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous:
                                i18n.language == "ar" ? "prevAr" : "prevEn",
                              next: i18n.language == "ar" ? "nextAr" : "nextEn",
                            }}
                            {...item}
                          />
                        )}
                        className={i18n.language == "ar" ? "arArrow" : null}
                        defaultPage={1}
                        page={page}
                        count={resp.last_page}
                        onChange={(e, value) => {
                          filter(value);
                          setPage(value);
                        }}
                        shape="rounded"
                        variant="outlined"
                      />
                    </Stack>

                    {/*
  <nav aria-label="Page navigation example">
  <ul className="pagination">
    <li onClick={()=>filter(resp.current_page-1)} className={i18n.language=="ar"?resp.current_page==1? "page-item prevAr disabled":"page-item prevAr": resp.current_page==1?"page-item disabled":"page-item"}><a className="page-link" >{t("orders.prev")}</a></li>
  
   

{Array(resp.last_page).fill(1).map((el, i) =>

<li onClick={()=>filter(i+1)} className={i+1==resp.current_page?"page-item active":"page-item"}><a className="page-link " >{i+1}</a></li>



  )}




    <li  onClick={()=>filter(resp.current_page+1)} className={i18n.language=="ar"? resp.current_page==resp.last_page?"page-item nextAr disabled":"page-item nextAr ": resp.current_page==resp.last_page?"page-item disabled":"page-item"}><a className="page-link" >{t("orders.next")}</a></li>
  </ul>
</nav>
*/}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default OrdersList;
