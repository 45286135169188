import "../Styles/MyOrders.css";

import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import bckg from "../Images/order.svg";
import CleartCart from "../Components/CleartCart";
import status1Active from "../Images/status1-active.svg";
import status1UnActive from "../Images/status1-unactive.svg";
import status2Active from "../Images/status2-active.svg";
import status2UnActive from "../Images/status2-unactive.svg";
import status3Active from "../Images/status3-active.svg";
import status3UnActive from "../Images/status3-unactive.svg";
import status4Active from "../Images/status4-active.svg";
import status4UnActive from "../Images/status4-unactive.svg";
import { RiArrowRightSFill, RiArrowLeftSFill } from "react-icons/ri";
import { BeatLoader } from "react-spinners";
import { useState, useEffect } from "react";
import {
  singleOrder,
  edit_order,
  Get_Basket,
  isAvailTable,
  orderBranches,
  accept_order,
  partial_pyament,
  awafiBalance,
} from "../api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ErrorModal from "../Components/ErrorModal";
import { MDBDataTable } from "mdbreact";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";

function PartialPayment() {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();
  const [errModal, setErrModal] = useState(false);
  const [arMsg, setArMsg] = useState();
  const [enMsg, setEnMsg] = useState();
  const [status, setStatus] = useState();
  const [repeatType, setRepeatType] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [remainAttem, setRemainAttem] = useState();
  const [orderMeals, setOrderMeals] = useState([]);
  const [remainingAmount, setRemainingAmount] = useState();
  const [
    remainingPartialPaymentsAttempts,
    setRemainingPartialPaymentsAttempts,
  ] = useState();
  const [previousPayments, setPreviousPayments] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState();

  const [clearModal, setClearModal] = useState(false);
  const [basket, setBasket] = useState([]);
  const [timeDifference, setTimeDifference] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const [balance, setBalance] = useState(
    localStorage.getItem("userAwaifiBalance")
  );

  const [ws, setWs] = useState(null);

  const [paymentType, setPaymentType] = useState("");
  const [amount, setAmount] = useState("");
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const { id } = useParams();

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);

    setShowConfirmButton(false);
  };

  const handleAmountChange = (e) => {
    let inputValue = e.target.value;

    // Allow empty input
    setAmount(inputValue);
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleOrderSuccessRedirect = () => {
    const token = window.localStorage.getItem("token");

    localStorage.removeItem("offer");
    localStorage.removeItem("orderDate");
    localStorage.removeItem("orderTime");
    localStorage.removeItem("tableNum");
    localStorage.removeItem("displayId");
    localStorage.removeItem("orderType");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("now");
    localStorage.removeItem("orderSource");
    localStorage.removeItem("branchOrderTime");
    // localStorage.removeItem("customerId");
    localStorage.removeItem("customerPhone");

    if (localStorage.getItem("type") !== "cashier") {
      localStorage.removeItem("branchId");
    }
    localStorage.removeItem("accountType");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("orderDistrictId");
    localStorage.removeItem("addressId");
    localStorage.removeItem("externalOrderId");

    localStorage.setItem("orderId", id);

    localStorage.removeItem("notes");
    localStorage.removeItem("tableDiscount");
    localStorage.removeItem("tableDiscountType");
    localStorage.removeItem("tableAccount");

    localStorage.removeItem("PaymentMethodDisc");
    localStorage.removeItem("promoCode");

    localStorage.removeItem("notesCheckbox");
    localStorage.removeItem("notesOffline");

    localStorage.setItem("discount", "false");
    localStorage.removeItem("PaymentMethodDiscContd");

    if (localStorage.getItem("cancelOrderId")) {
      cancel_direct(localStorage.getItem("cancelOrderId"), token)
        .then((response) => {
          localStorage.removeItem("editNotes");
          localStorage.removeItem("cancelOrderId");
          localStorage.removeItem("editPaymentMethod");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            history("/");
          }
        });
    }

    history("/order-success");
  };

  useEffect(() => {
    if (localStorage.getItem("type") === "cashier") {
      connectWebSocket();
      return () => {
        if (ws) {
          ws.close();
          console.log("WebSocket connection closed.");
        }
      };
    }
  }, []);

  const convertArabicToEnglish = (num) => {
    return num
      .toString()
      .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => "0123456789"["٠١٢٣٤٥٦٧٨٩".indexOf(d)]);
  };

  const handlePaymentPartial = (
    transactionId = "",
    isSuccess = 1,
    rrn = ""
  ) => {
    const paymentMeth =
      JSON.parse(localStorage.getItem("currentPayment")) == "shabka"
        ? "intrasoft"
        : JSON.parse(localStorage.getItem("currentPayment"));
    setLoading(true);
    try {
      const data = {
        amount: amount || JSON.parse(localStorage.getItem("currentAmount")),
        paymentMethod: paymentMeth,
        isSuccess: isSuccess,
        transactionId: transactionId,
        rrn: rrn,
        checkNetworkResposne: localStorage.getItem("message") || "",
        cardScheme: localStorage.getItem("cardScheme") || "",
        cardType: localStorage.getItem("cardType"),
      };
      console.log(data);
      partial_pyament(id, data, localStorage.getItem("token"))
        .then((response) => {
          // Handle success
          localStorage.removeItem("message");
          localStorage.removeItem("cardScheme");
          localStorage.removeItem("cardType");

          const newRemainingAmount = response.data.data.remainingPaymentAmount;
          const newRemainingPartialPaymentsAttempts =
            response.data.data.remainingPartialPaymentsAttemps;

          setRemainAttem(newRemainingPartialPaymentsAttempts);
          setRemainingAmount(newRemainingAmount);
          localStorage.setItem(
            "remianPrice",
            JSON.stringify(newRemainingAmount)
          );

          setAmount(parseFloat(newRemainingAmount).toFixed(2));
          localStorage.setItem(
            "amountToPay",
            parseFloat(amountToPay).toFixed(2)
          );

          setRemainingPartialPaymentsAttempts(
            newRemainingPartialPaymentsAttempts
          );

          const paymentMethod =
            response.data.data.remainingPaymentAmount.paymentMethod;
          setPaymentMethod(paymentMethod);
          console.log(response.data.data);
          // Push current payment to previousPayments with remaining attempts and amount
          setPreviousPayments([
            ...previousPayments,
            {
              amount: amount, // Ensure the amount of the current payment is logged correctly
              isSuccess: isSuccess,
              paymentType: paymentType,
              remainingPartialPaymentsAttempts:
                newRemainingPartialPaymentsAttempts,
              remainingPaymentAmount: newRemainingAmount,
            },
          ]);

          // Reset for next payment attempt
          getBalance();
          setPaymentType("");
          setShowConfirmButton(false);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          localStorage.removeItem("message");
          localStorage.removeItem("cardScheme");
          localStorage.removeItem("cardType");
          getBalance();
          // console.log(error.response.data.error)

          if (error.response.data.error === "exceed_expired_wallet_balance") {
            showError(
              " يجب ان يكون رصيد عوافي اكبر من مبلغ الاوردر ",
              "Awafi Balance should be greater than order price."
            );
          } else {
            showError(
              error.response.data.error.toString(),
              error.response.data.error.toString()
            );
            // showError(
            //   " استنذفت عدد محاولاتك ",
            //   "you running out of remaining."
            // );
          }
        });
    } catch (e) {
      setLoading(false);
      // Handle exception
    }
  };

  useEffect(() => {
    console.log(amount);
    localStorage.setItem("currentAmount", JSON.stringify(amount));
  }, [amount]);

  useEffect(() => {
    console.log(paymentType);
    localStorage.setItem("currentPayment", JSON.stringify(paymentType));
  }, [paymentType]);

  const connectWebSocket = () => {
    const socket = new WebSocket("ws://localhost:7001/intersoft_integration");
    socket.onopen = () => {
      setWs(socket);
    };
    socket.onmessage = (evt) => {
      console.log("Message received:", evt.data);
      handlePosResponse(evt.data, localStorage.getItem("orderId"));
    };
    socket.onerror = (evt) => {
      console.error("WebSocket error:", evt.message);
      showError(
        "حدث خطأ في WebSocket. حاول مرة أخرى.",
        "WebSocket error occurred. Please try again."
      );
    };
    socket.onclose = () => {
      setStatus("disconnected");
      showError(
        "حدث خطأ في WebSocket. حاول مرة أخرى.",
        "WebSocket error occurred. Please try again."
      );
      console.log("WebSocket connection closed.");
      setWs(null);
    };
  };

  const handlePosResponse = (message, orderId) => {
    const rrnMatch = message.match(/<RRN>(.*?)<\/RRN>/);
    const rrn = rrnMatch ? rrnMatch[1] : null;
    const terminalMatch = message.match(/<TerminalID>(.*?)<\/TerminalID>/);
    const terminal = terminalMatch ? terminalMatch[1] : null;
    const cardSchemeMatch = message.match(
      /<CardScheme ID="(.*?)" .*?English="(.*?)"/
    );

    const cardScheme = cardSchemeMatch
      ? `${cardSchemeMatch[2]}-${cardSchemeMatch[1]}`
      : null;
    const cardType = cardSchemeMatch ? `${cardSchemeMatch[2]}` : null;

    if (cardType) {
      localStorage.setItem("cardType", cardType);
    }

    // Save CardScheme in local storage
    if (cardScheme) {
      localStorage.setItem("cardScheme", cardScheme);
    }
    if (rrn) {
      localStorage.setItem("rrn", rrn);
    }

    if (message) {
      localStorage.setItem("message", message);
    }

    const transactionId = `${terminal}-${rrn}`;

    if (message.includes('<Result Arabic="مقبولة" English="APPROVED"/>')) {
      handlePaymentPartial(transactionId, 1, rrn);
    } else {
      handlePaymentPartial(null, 0); // Pass failure
    }
  };

  const handleConfirmPayment = () => {
    if (paymentType === "shabka") {
      handlePosPayment();
    } else {
      handlePaymentPartial(); // Call handlePaymentPartial for other payment types
    }
  };

  const getBalance = () => {
    setLoading(true);
    const orderData = JSON.parse(localStorage.getItem("orderData"));

    awafiBalance(localStorage.getItem("token"), orderData.user.phone)
      .then((res) => {
        console.log(res.data.data.expirableWalletBalance);
        localStorage.setItem(
          "userAwaifiBalance",
          res.data.data.expirableWalletBalance
        );
        setBalance(res.data.data.expirableWalletBalance);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const acceptOrder = (orderId, transactionId) => {
    const token = window.localStorage.getItem("token");

    const data = {
      transactionId: transactionId,
      cardType: localStorage.getItem("cardScheme"),
      platform: "agent",
    };

    accept_order(data, token, orderId)
      .then((response) => {
        handleOrderSuccess(response);
      })
      .catch((error) => {
        showError(
          "حدث خطأ في قبول الطلب. حاول مرة أخرى.",
          "Error accepting order. Please try again."
        );
        console.error("Error accepting order:", error);
      });
  };
  const handleOrderSuccess = (response) => {
    const token = window.localStorage.getItem("token");

    localStorage.removeItem("offer");
    localStorage.removeItem("orderDate");
    // localStorage.removeItem("customerId");
    localStorage.removeItem("orderTime");
    localStorage.removeItem("tableNum");
    localStorage.removeItem("displayId");
    localStorage.removeItem("orderType");
    localStorage.removeItem("now");
    localStorage.removeItem("orderSource");
    localStorage.removeItem("branchOrderTime");

    if (localStorage.getItem("type") !== "cashier") {
      localStorage.removeItem("branchId");
    }
    localStorage.removeItem("accountType");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("orderDistrictId");
    localStorage.removeItem("addressId");
    localStorage.removeItem("externalOrderId");

    localStorage.setItem("orderId", response.data.data.orderId);

    localStorage.removeItem("notes");
    localStorage.removeItem("tableDiscount");
    localStorage.removeItem("tableDiscountType");
    localStorage.removeItem("tableAccount");

    localStorage.removeItem("PaymentMethodDisc");
    localStorage.removeItem("promoCode");

    localStorage.removeItem("notesCheckbox");

    localStorage.setItem("discount", "false");
    localStorage.removeItem("PaymentMethodDiscContd");
  };

  const showError = (arMsg, enMsg) => {
    setErrModal(true);
    setArMsg(arMsg);
    setEnMsg(enMsg);
    setTimeout(() => {
      setErrModal(false);
    }, 2000);
    setSubmitLoading(false);
  };
  const handlePosPayment = () => {
    if (ws) {
      ws.send(
        JSON.stringify({
          Command: "SALE",
          Amount: (amount * 100).toString(),
          OriginalRRN: "",
          MaskedPan: "",
          PrintFlag: "1",
          AdditionalData: "",
        })
      );
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    singleOrder(token, id)
      .then((response) => {
        const createdDate = response.data.data.createdAt;
        const before = new Date(
          `${
            monthNames[parseInt(createdDate.slice(5, 7) - 1)]
          } ${createdDate.slice(8, 10)}, ${createdDate.slice(
            0,
            4
          )} ${createdDate.slice(11, 13)}:${createdDate.slice(14, 16)}`
        );
        setPreviousPayments(response.data.data.partialPayments || []);
        setCreatedAt(before);
        if (diff_hours(before) > 20) {
          setTimeDifference(true);
        } else {
          setTimeDifference(false);
        }

        // setStatus(response.data.data.statusId);
        setOrder(response.data.data);
        localStorage.setItem("orderData", JSON.stringify(response.data.data));
        setRemainingAmount(response.data.data.totalPrice);
        if (
          response.data.data.partialPayments &&
          response.data.data.partialPayments.length > 0
        ) {
          const totalPartialPayments =
            response.data.data.partialPayments.reduce(
              (acc, payment) => acc + payment.amount,
              0
            );
          localStorage.setItem(
            "remianPrice",
            parseFloat(response.data.data.totalPrice) -
              parseFloat(totalPartialPayments)
          );
          setAmount(
            parseFloat(
              response.data.data.totalPrice - totalPartialPayments
            ).toFixed(2)
          );
          localStorage.setItem(
            "amountToPay",
            parseFloat(
              response.data.data.totalPrice - totalPartialPayments
            ).toFixed(2)
          );
        } else {
          localStorage.setItem(
            "remianPrice",
            parseFloat(response.data.data.totalPrice).toFixed(2)
          );
          setAmount(parseFloat(response.data.data.totalPrice).toFixed(2));
          localStorage.setItem(
            "amountToPay",
            parseFloat(response.data.data.totalPrice).toFixed(2)
          );
        }

        setRemainingPartialPaymentsAttempts(
          response.data.data.remainingPartialPaymentsAttemps || 3
        );

        setOrderMeals(response.data.data.products);
        // ... other logic

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          history("/");
        }
      });
  }, [balance]);

  function diff_hours(dt1) {
    var today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let day = today.getDate();
    let hour = today.getHours();
    let minute = today.getMinutes();

    let dt2 = new Date(
      `${monthNames[month]} ${day}, ${year} ${hour}:${minute}`
    );

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;

    return Math.abs(Math.round(diff));
  }

  function handleEdit(edit_type) {
    localStorage.removeItem("cancelOrderId");

    if (diff_hours(createdAt) > 20 && edit_type == "cancel") {
      setTimeDifference(true);
    } else {
      setLoading(true);
      setClearModal(false);
      const token = localStorage.getItem("token");

      edit_order(token, order.id, order.user.id).then((response) => {
        localStorage.setItem("discount", "false");
        localStorage.removeItem("PaymentMethodDisc");
        localStorage.removeItem("PaymentMethodDiscContd");
        localStorage.removeItem("promoCode");
        localStorage.removeItem("notes");
        localStorage.removeItem("notesCheckbox");
        localStorage.removeItem("tableDiscount");
        localStorage.removeItem("tableDiscountType");
        localStorage.removeItem("tableAccount");
        localStorage.removeItem("tableNum", order.tableId);
        localStorage.removeItem("displayId", order.tableDisplayId);

        localStorage.setItem("customerId", order.user.id);
        localStorage.setItem("customerName", order.user.name);
        localStorage.setItem("customerNotes", order.user.notes);
        localStorage.setItem("customerPhone", order.user.phone);
        localStorage.setItem("customerPhoto", order.user.photo);
        localStorage.setItem("customerAwafi", order.user.joinLoyalty);

        localStorage.setItem("arAddress", order.addressArAddress);
        localStorage.setItem("enAddress", order.addressEnAddress);

        localStorage.setItem("branchNameAr", order.branchArName);
        localStorage.setItem("branchNameEn", order.branchEnName);

        localStorage.setItem("branchId", order.branchId);
        localStorage.setItem("now", order.now);
        localStorage.setItem("orderDate", order.date);
        localStorage.setItem("orderTime", order.time);
        localStorage.setItem("editPaymentMethod", order.paymentMethod);
        localStorage.setItem("editNotes", order.notes);

        if (edit_type == "cancel") {
          console.log("set_orderID");
          localStorage.setItem("cancelOrderId", order.id);
        }
        localStorage.setItem(
          "orderSourceObj",
          JSON.stringify({
            id: 6,
            name: "Agent",
            code: 5,
            active: 1,
            allowPaid: 0,
            allowCash: 1,
            allowPaymentOnline: 1,
          })
        );
        localStorage.setItem("orderSource", 5);
        localStorage.setItem("orderType", order.typeId);
        if (order.typeId == 2) {
          localStorage.setItem("addressId", order.addressId);
        }

        if (order.typeId == 5 && order.statusId == 9) {
          history("/menu");
          localStorage.setItem("tableNum", 0);
          localStorage.setItem("displayId", 0);
        } else if (order.typeId == 5) {
          isAvailTable(token, parseInt(order.branchId), parseInt(order.tableId))
            .then((response) => {
              if (response.data.data == true) {
                localStorage.setItem("tableNum", order.tableId);
                localStorage.setItem("displayId", order.tableDisplayId);

                history("/menu");
              } else {
                setArMsg(
                  `الجلسه ${order.tableDisplayId} غير متاحه حاليا٬ يمكنك اختيار جلسه آخرى`
                );
                setEnMsg(
                  `Table ${order.tableDisplayId} isn’t available now, you can choose another table.`
                );

                setErrModal(true);

                setLoading(false);
                setTimeout(function () {
                  history("/add-order");
                }, 2700);
              }
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                history("/");
              }
            });
        } else {
          history("/menu");
        }
      });
    }
  }

  useEffect(() => {
    return () => {
      console.log("get started");
      localStorage.removeItem("userAwaifiBalance");
    };
  }, []);

  useEffect(() => {
    if (remainingAmount == 0) {
      handleOrderSuccessRedirect();
    }
  }, [remainingPartialPaymentsAttempts, remainingAmount]);

  const data = {
    columns: [
      {
        label: t("singleOrder.name"),
        field: i18n.language == "ar" ? "productArName" : "productEnName",
        sort: "asc",
      },
      {
        label: t("menu.size"),
        field: i18n.language == "ar" ? "sizeArName" : "sizeEnName",
        sort: "asc",
      },
      {
        label: t("cookingTypes"),
        field:
          i18n.language == "ar" ? "cookingTypeArName" : "cookingTypeEnName",
        sort: "asc",
      },

      {
        label: t("menu.quanitity"),
        field: "quantity",
        sort: "asc",
      },

      {
        label: t("singleOrder.price"),
        field: "price",
        sort: "asc",
      },
      {
        label: t("menu.additionals"),
        field: "additionals",
        sort: "asc",
      },

      {
        label: t("addOrder.extras"),
        field: "extras",
        sort: "asc",
      },
    ],
    rows: orderMeals,
  };

  return (
    <div>
      {clearModal ? (
        <CleartCart
          handleClear={() => {
            handleEdit("repeatType");
          }}
          setModal={setClearModal}
        />
      ) : null}
      {errModal ? (
        <ErrorModal
          setModal={setErrModal}
          msg={i18n.language == "ar" ? arMsg : enMsg}
        />
      ) : null}
      {loading ? (
        <div style={{ height: "60vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div
          className="container"
          style={{ marginTop: "2em", paddingBottom: "70px" }}
        >
          <div className="orderData">
            {((parseInt(order.statusId) === 1 &&
              order.paymentMethod === "cash") ||
              (parseInt(order.statusId) === 2 &&
                order.paymentMethod === "cash") ||
              (parseInt(order.statusId) === 6 &&
                order.paymentMethod === "tap") ||
              (parseInt(order.statusId) === 9 &&
                order.paymentMethod === "cash")) &&
            order.source == "Agent" &&
            !timeDifference &&
            order.editOrder == 1 ? (
              <div style={{ textAlign: "end" }}>
                <button
                  className="editOrder"
                  onClick={
                    basket.length > 0
                      ? () => {
                          setClearModal(true);
                          setRepeatType("cancel");
                        }
                      : () => {
                          handleEdit("cancel");
                        }
                  }
                >
                  {t("singleOrder.edit")}
                </button>
              </div>
            ) : null}

            {parseInt(order.statusId) === 4 ? (
              <div style={{ textAlign: "end" }}>
                <button
                  className="editOrder"
                  onClick={
                    basket.length > 0
                      ? () => {
                          setClearModal(true);
                          setRepeatType("repeat");
                        }
                      : () => {
                          handleEdit("repeat");
                        }
                  }
                >
                  {t("singleOrder.repeat_order")}
                </button>
              </div>
            ) : null}
            <div className="container-fluid">
              <div className="row orderDetails">
                <div className="col-sm-6">
                  <ul>
                    <li>
                      <span>{t("profile.orderId") + ":"}</span> {"#" + order.id}
                    </li>
                    <li>
                      <span>{t("singleOrder.name") + ":"}</span>{" "}
                      {order.user.name}
                    </li>
                    <li>
                      <span>{t("singleOrder.phone") + ":"}</span>{" "}
                      {order.user.phone}
                    </li>
                    <li>
                      <span>{t("singleOrder.branch") + ":"}</span>{" "}
                      {i18n.language == "ar"
                        ? order.branchArName
                        : order.branchEnName}
                    </li>
                    <li>
                      <span>{t("addOrder.type") + ":"}</span>{" "}
                      {order.typeId == 1
                        ? t("addOrder.branch")
                        : order.typeId == 5
                        ? t("menu.tables")
                        : t("addOrder.home")}
                    </li>

                    <li>
                      <span>{t("orders.creator") + ":"}</span>{" "}
                      {order.creatorName}
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    {order.typeId == 2 ? (
                      <li style={{ maxWidth: "85%" }}>
                        <span>{t("profile.address_details") + ":"}</span>{" "}
                        {i18n.language == "ar"
                          ? order.addressArAddress
                          : order.addressEnAddress}
                      </li>
                    ) : null}
                    {order.typeId == 2 ? (
                      <li>
                        <span>{t("singleOrder.driver")}</span>{" "}
                        {order.driverPhone}
                      </li>
                    ) : null}
                    <li>
                      <span>{t("addOrder.status") + ":"}</span>{" "}
                      {order.statusId == 1
                        ? t("singleOrder.order_accepted")
                        : order.statusId == 2
                        ? order.typeId == 2
                          ? t("singleOrder.order_InProgress")
                          : t("singleOrder.order_InProgressBranch")
                        : order.statusId == 3
                        ? t("singleOrder.order_with_driver")
                        : order.statusId == 6
                        ? t("singleOrder.waiting")
                        : order.typeId == 2
                        ? t("singleOrder.done_order")
                        : t("singleOrder.done_orderBranch")}
                    </li>

                    <li>
                      <span>{t("orders.sync") + ":"}</span>{" "}
                      {order.sync == 1
                        ? t("orders.synced")
                        : t("orders.not-synced")}
                    </li>
                    <li>
                      <span>{t("orders.creation") + ":"}</span>{" "}
                      {order.createdAt}
                    </li>
                    <li>
                      <span>{t("singleOrder.order_time") + ":"}</span>{" "}
                      {order.now
                        ? t("addOrder.now")
                        : order.date + " " + order.time}
                    </li>
                  </ul>
                </div>
                <div className="col-12 borderHr">
                  <ul>
                    <li>
                      <span> {t("singleOrder.notes")}</span> {order.notes}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="invoiceTable">
            <p className="invoice">{t("singleOrder.invoice")}</p>
            <table className="allOrders" style={{ width: "100%" }}>
              <tr>
                <th>{t("singleOrder.price")}</th>
                <th>{t("singleOrder.deliveryFees")}</th>
                <th>{t("singleOrder.disc_mount")}</th>
                <th>{t("menu.walletDisc")}</th>

                <th>{t("orders.orderTotal")}</th>
                <th>{t("orders.paymentMethod")}</th>
              </tr>

              <tr>
                <td>
                  {order.originalPrice} &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>
                <td>
                  {order.deliveryPrice} &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>
                <td>
                  {order.discount} &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>

                <td>{order.walletPay}</td>
                <td>
                  {order.totalPrice} &nbsp;
                  {i18n.language == "ar" ? order.arCurrency : order.enCurrency}
                </td>

                <td>{t("partial")}</td>
              </tr>
            </table>
          </div>

          {previousPayments.length !== 0 && (
            <div style={{ marginTop: "60px" }} className="invoiceTable">
              <p className="invoice">{t("previousPayment")}</p>
              <table className="allOrders" style={{ width: "100%" }}>
                <tr>
                  <th>{t("amountPaid")}</th>
                  <th>{t("menu.paymentMethod")}</th>
                  <th> {t("TransactionId")}</th>
                  <th> {t("paymentStatus")}</th>

                  {/* <th>{t("numberOfRemaining")}</th>
                  <th>{t("remainingAmount")}</th> */}
                </tr>

                {previousPayments.map((payment, index) => {
                  console.log(payment);
                  return (
                    <tr
                      key={index}
                      style={{
                        textAlign: i18n.language == "ar" ? "right" : "left",
                      }}
                    >
                      <td>
                        {payment.amount} &nbsp;
                        {i18n.language === "ar"
                          ? order.arCurrency
                          : order.enCurrency}
                      </td>
                      <td>
                        {payment.paymentType ?? payment.paymentMethod} &nbsp;
                      </td>
                      <td>{payment.transactionId} &nbsp;</td>
                      <td>
                        {payment.isSuccess == 1 ? t("paySucess") : t("payFail")}{" "}
                        &nbsp;
                      </td>

                      {/* <td>{payment.remainingPartialPaymentsAttempts} &nbsp;</td>
                        <td>{payment.remainingPaymentAmount} &nbsp;</td> */}
                    </tr>
                  );
                })}
              </table>
            </div>
          )}

          {remainingPartialPaymentsAttempts > 0 && remainingAmount > 0 && (
            <div style={{ marginTop: "40px" }} className="invoiceTable">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "200px",
                  }}
                >
                  <label
                    htmlFor="paymentType"
                    style={{ fontWeight: "bold", textAlign: "right" }}
                  >
                    {t("pickorderway")}
                  </label>
                  <select
                    id="paymentType"
                    className="form-control"
                    value={paymentType}
                    onChange={handlePaymentTypeChange}
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      width: "100%",
                    }}
                  >
                    <option value="">{t("profile.choose")}</option>
                    <option value="cash">{t("menu.cash")}</option>
                    <option value="shabka">{t("shabka")}</option>
                    {/* {localStorage.getItem("userAwaifiBalance") > amount &&<option value="expirable_wallet">{t("profile.awafiWallet")}</option>} */}
                    <option value="expirable_wallet">
                      {t("profile.awafiWallet")} ({balance}{" "}
                      {i18n.language == "en" ? "SAR" : "ريال"} ){" "}
                    </option>
                  </select>
                </div>

                {paymentType && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "200px",
                    }}
                  >
                    <label
                      htmlFor="amount"
                      style={{ fontWeight: "bold", textAlign: "right" }}
                    >
                      {t("profile.amount")}
                    </label>
                    <input
                      disabled={remainingPartialPaymentsAttempts === 1}
                      type="text"
                      id="amount"
                      value={amount} // Ensure it's a float and fix to 1 decimal
                      className="form-control"
                      onChange={handleAmountChange}
                      placeholder={`${t("profile.amount")}`}
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        width: "100%",
                      }}
                    />
                  </div>
                )}

                {paymentType && (
                  <div style={{ marginTop: "33px" }} className="backBtn">
                    <button
                      disabled={
                        !amount ||
                        amount <= 0 ||
                        (JSON.parse(localStorage.getItem("remianPrice")) &&
                          parseFloat(
                            JSON.parse(localStorage.getItem("remianPrice")).toFixed(2)
                          ) < amount)
                      }
                      onClick={handleConfirmPayment}
                    >
                      {t("profile.confirm")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PartialPayment;
